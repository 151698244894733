const suffixImg = require('@/static/chat-soild.svg')
const logo = require('@/static/logo-bg.svg')

const tuneTalker = require('@/static/bxs-music.svg')
const tuneTalkerBg = require('@/static/bg-tune-talker.svg')
const tuneTalker1Bg = require('@/static/bg-tune-talker-1.svg')

const foodForMood = require('@/static/bxs-bowl-hot.svg')
const foodForMoodBg = require('@/static/bg-food-for-mood.svg')
const foodForMood1Bg = require('@/static/bg-food-for-mood-1.svg')

const suffixFree = require('@/static/chat-soild.svg')
const suffixWellbeing = require('@/static/chat-orange-solid.svg')
const suffixPro = require('@/static/chat-green-solid.svg')
const suffixBlack = require('@/static/chat-solid-black.svg')

const tuneTalkerGreetings = [
  'Strumming into your day with Tune Talker! 🎸 What song has been on your mind lately?',
  'Hit play with Tune Talker! 🎶 Share with me, what tune lifts your mood?',
  'Tune Talker tuning in! 🎷 Got a melody that moves you? Let\'s chat about it!',
  'Groove into good vibes with Tune Talker! 🎧 What\'s your go-to genre these days?',
  'Melodies await with Tune Talker! 🎹 Tell me, what\'s the last song you played on repeat?',
  'Rhythm and chat with Tune Talker! 🥁 What music makes you feel alive?',
  'Harmonise your day with Tune Talker! 🎤 Is there a lyric that speaks to you right now?',
  'Welcome to Tune Talker! 🎵 Let’s dive into the world of music and uplift your spirits. What’s on your playlist today?'
]

const foodMoodGreetings = [
  'Savour the flavour with Food for Mood! 🥘 What\'s a dish that always brings you joy?',
  'Cooking up conversation with Food for Mood! 🍳 What\'s your recent kitchen adventure?',
  'Food for Mood, at your service! 🍔 Craving something? Let\'s talk delicious!',
  'Bite into bliss with Food for Mood! 🍜 What\'s a meal that comforts your soul?',
  'Whisking up the warmth with Food for Mood! 🍪 What\'s your favourite sweet treat?',
  'Gastronomic chat begins with Food for Mood! 🍲 What cuisine do you love exploring?',
  'Plate up some happiness with Food for Mood! 🍛 Tell me, what\'s your ultimate comfort food?',
  'Hello from Food for Mood! 🍲 Ready to stir up some tasty talks that\'ll whet your appetite and brighten your day? What\'s your favourite comfort food?'
]

// const emo23Greetings = [
//   "Good day, <Name>! It's Ex-23. Ready to chat and unwind?",
//   "Hi there, <Name>! Ex-23 here, eager to catch up. How are you today?",
//   "Hello, <Name>! It's Ex-23, your AI pal. What's on your mind?",
//   "Hey <Name>, Ex-23 at your service! Ready to share your thoughts?",
//   "Hi <Name>! Ex-23's here to lend an ear. What's happening?",
//   "Good to see you, <Name>! It's Ex-23. How's your day going?",
//   "Hey <Name>, it's Ex-23. Your secrets are always safe with me. What's on your mind?",
//   "Hey <Name>! You can trust me with your thoughts. What would you like to talk about today?",
//   "Hi again, <Name>! Remember, I'm a vault for your secrets. How are you?",
//   "Hello <Name>, Ex-23 here. You know you can trust me, right? What's up?",
//   "Hey <Name>, it's Ex-23. Your privacy is my priority. What's troubling you?",
//   "Hi <Name>! Rest easy, I'm all ears and no leaks. What's going on?",
//   "Hello <Name>, it's Ex-23. No judgments here, just a listening ear. What's going on?",
//   "Hey <Name>, good to see you. You'll always get a non-biased ear with me. What's new?",
//   "Hi <Name>! I'm here to offer non-judgmental support. How's your day going?",
//   "Greetings, <Name>. Ex-23 here. I promise, no bias, just an open ear. What would you like to talk about?",
//   "Hey there, <Name>! Just a reminder, I'm your neutral zone. What's troubling you?",
//   "Hello <Name>, with me, it's always a non-biased conversation. How are you today?"
// ]

const characters = [
  {
    logo,
    tpl: true,
    id: 'asst_4qvcMuiHFUbDVT05XA1fNt2E',
    name: 'Ex-23',
    pro: false,
    free: true,
    text1: 'EX-23 is your default AI companion, offering a blend of futuristic insight with a touch of human warmth.',
    suffix: suffixImg,
    background: null,
    greetings: []// emo23Greetings,
  },
  {
    logo: tuneTalker,
    name: 'Tune Talker',
    text1: 'Your AI music enthusiast, engaging you in uplifting conversations about music to enhance your emotional well-being.',
    suffix: suffixImg,
    background: tuneTalkerBg,
    smallBackground: tuneTalker1Bg,
    greetings: tuneTalkerGreetings,
    tpl: true
  },
  {
    logo: foodForMood,
    name: 'Food for Mood',
    text1: 'Your AI culinary companion, sparking delightful discussions about food to nourish your mood and spirit.',
    suffix: suffixImg,
    background: foodForMoodBg,
    smallBackground: foodForMood1Bg,
    greetings: foodMoodGreetings,
    tpl: true
  },
  { highlight: true, tpl: true },
  { pro: true, logo: '', name: '', suffix: '', greetings: [], tpl: true },
  { pro: true, logo: '', name: '', suffix: '', greetings: [], tpl: true }
]

const genders = [
  'Male',
  'Female',
  'Non-binary',
  'Transgender',
  'Intersex',
  'Prefer not to say'
]

const ages = ['16 - 24', '25 - 34', '35 - 44', '16 - 24', '55+']

const defaultMemberShip = {
  memberships: [],
  dailyTrial: { trialCount: 0, createdAt: '2024-04-03' },
  dailyAvailable: 5
}
const defaultProfile = {
  ageRange: -1,
  mail: '--',
  emailVerified: false,
  gender: -1,
  id: '--',
  referralCode: '--',
  timezone: '--'
}

const proSubscriptionTps = [
  {
    name: 'Annual Subscription',
    background:
      'linear-gradient(95deg, #78F073 11.09%, #40DBB5 59.2%, #18CDE3 92.83%)',
    descs: [
      'Suitable for long-term condition management.',
      'Enjoy uninterrupted access to professional insights for a full year, saving 50% compared to monthly payments.',
      'Ideal for users committed to continuous personal growth and health management.'
    ],
    price: 'NZ$1,500',
    unit: 'year',
    tId: 'year-1',
    month: 12
  },
  {
    name: 'Half-Yearly Subscription',
    background: '#78F073',
    descs: [
      'Ideal for those undergoing seasonal or semi-annual treatment cycles.',
      'Secure 6 months of consistent support while saving 30% over the monthly rate. ',
      'Great for maintaining progress with manageable commitment.'
    ],
    price: 'NZ$1,050',
    unit: '6-month',
    tId: 'month-6',
    month: 6
  },
  {
    name: 'Quarterly Subscription',
    background: '#18CDE3',
    descs: [
      'Perfect for addressing specific short-term health goals or conditions.',
      'A three-month commitment offers flexibility and a moderate cost advantage of 10% savings over the monthly rate, making it a practical choice for many users.'
    ],
    price: 'NZ$675',
    unit: '3-month',
    tId: 'month-3',
    month: 3
  },
  {
    name: 'Monthly Subscription',
    background: '#FDC2FF',
    descs: [
      'Optimal for users evaluating new strategies for their health and wellbeing or those needing temporary support.',
      'Subscribe month-to-month with the freedom to cancel anytime, ensuring flexibility and control over your health journey.'
    ],
    price: 'NZ$250',
    unit: 'month',
    tId: 'month-1',
    month: 1
  },
  {
    name: 'Weekly Subscription',
    background: '#FFCDB4',
    descs: [
      'Perfect for users needing short bursts of professional advice without long-term commitment.'
    ],
    price: 'NZ$100',
    unit: 'week',
    tId: 'week-1',
    month: 0
  }
]

const wellbeingSubscriptionTps = [
  {
    name: 'Annual Subscription',
    background:
      'linear-gradient(95deg, #78F073 11.09%, #40DBB5 59.2%, #18CDE3 92.83%)',
    descs: [
      'Suitable for long-term condition management.',
      'Enjoy uninterrupted access to professional insights for a full year, saving 50% compared to monthly payments.',
      'Ideal for users committed to continuous personal growth and health management.'
    ],
    price: 'NZ$1,500',
    unit: 'year',
    tId: 'year-1',
    month: 12
  },
  {
    name: 'Half-Yearly Subscription',
    background: '#78F073',
    descs: [
      'Ideal for those undergoing seasonal or semi-annual treatment cycles.',
      'Secure 6 months of consistent support while saving 30% over the monthly rate. ',
      'Great for maintaining progress with manageable commitment.'
    ],
    price: 'NZ$1,050',
    unit: '6-month',
    tId: 'month-6',
    month: 6
  },
  {
    name: 'Quarterly Subscription',
    background: '#18CDE3',
    descs: [
      'Perfect for addressing specific short-term health goals or conditions.',
      'A three-month commitment offers flexibility and a moderate cost advantage of 10% savings over the monthly rate, making it a practical choice for many users.'
    ],
    price: 'NZ$675',
    unit: '3-month',
    tId: 'month-3',
    month: 3
  },
  {
    name: 'Monthly Subscription',
    background: '#FDC2FF',
    descs: [
      'Ideal for travellers embarking on extended journeys or those who wish to take their time recovering and rejuvenating after their travels.',
      'Subscribe on a month-to-month basis, with the freedom to cancel anytime, ensuring continued support throughout your long trip or during your recovery phase.'
    ],
    price: 'NZ$250',
    unit: 'month',
    tId: 'month-1',
    month: 1
  },
  {
    name: 'Weekly Subscription',
    background: '#FFCDB4',
    descs: [
      'Perfect for shorter trips or quick getaways where you need focused well-being support without long-term commitment.',
      'Get the flexibility of a week-long subscription tailored to meet the needs of your brief travel periods.'
    ],
    price: 'NZ$100',
    unit: 'week',
    tId: 'week-1',
    month: 0
  }
]

export const state = () => ({
  user: null,
  idToken: null,
  membership: defaultMemberShip,
  characters,
  character: characters[0],
  genders,
  ages,
  profile: defaultProfile,
  isMobile: false,
  theme: 'dark',
  feellog: null,
  deletedLogs: [],
  showDownloadBanner: 1,
  conversations: [],
  systemBanner: null,
  pendingPayPlan: null,
  proSubscriptionTps,
  wellbeingSubscriptionTps,
})

export const mutations = {
  setUser(state, user) {
    if (user) {
      const obj = JSON.parse(JSON.stringify(user))
      state.user = obj
      state.conversations = []// 刷新greeting
    }
  },
  /**
   * 设置当前选中的 character
   * @param state
   * @param character
   */
  setCharacter(state, character) {
    const obj = character
    if (obj.own) {
      state.characters = state.characters.map((e) => {
        if (e.id === obj.id) {
          return {
            ...e,
            own: true,
          }
        } else {
          return e;
        }
      })
    }
    state.character = obj
  },
  setAvailableCharacters(state, characters) {
    // const isDark = state.theme === 'dark'
    state.characters = characters.map((e) => {
      if (e.free) {
        e.suffix = suffixFree
      } else if (e.type === 1) {
        e.suffix = suffixWellbeing
      } else {
        e.suffix = suffixPro
      }
      e.darkSuffix = suffixBlack
      if (!e.greetings) {
        e.greetings = []
      }// ["greetings0", "greetings1", "greetings2"]
      else {
        e.greetings = JSON.parse(e.greetings)
      }
      return e
    })
    if (state.character && state.character.tpl) {
      const list = characters.filter(e => e.id === 'asst_4qvcMuiHFUbDVT05XA1fNt2E')
      if (list.length) {
        state.character = list[0]
      }
    }
  },
  setIdToken(state, idToken) {
    state.idToken = idToken
  },
  setMembership(state, membership) {
    state.membership = membership
  },
  setAgeRange(state, ageIndex) {
    this.state.profile.ageRange = ageIndex
  },
  setGenderIndex(state, genderIndex) {
    this.state.profile.gender = genderIndex
  },
  setIsMobile(state, isMobile) {
    this.state.isMobile = isMobile
  },
  setTheme(state, theme) {
    if (~['dark', 'light'].indexOf(theme)) {
      state.theme = theme
    }
  },
  changeTheme(state, theme) {
    const next = theme || this.state.theme
    if (next === 'dark') {
      state.theme = 'light'
    } else {
      state.theme = 'dark'
    }
  },
  clearAll(state) {
    state.user = null
    state.idToken = null
    state.membership = defaultMemberShip
    state.profile = defaultProfile
    state.conversations = []
  },
  setFeellogs(state, logs) {
    state.feellog = logs
  },
  deleteFeellogs(state, logs) {
    state.deletedLogs = logs
  },
  setDownloadBannerVisible(state, visible) {
    state.showDownloadBanner = visible
  },
  setConversations(state, log) {
    // 先处理只有greetings 没有其他问答的character,重新生成 conversations
    const list = state.conversations.filter((e) => e.cid === log.cid)
    if (list.length === 1 && log.question.trim().length === 0) {
      state.conversations.forEach((e, i, arr) => {
        if (e.cid === log.cid) {
          arr.splice(i, 1)
        }
      })
    }
    state.conversations.push(log)
  },
  updateConversations(state, log) {
    state.conversations.forEach((e) => {
      if (e.question === log.question && e.qid === log.qid) {
        e.answer = log.answer
      }
    })
  },
  setSystemBanner(state, banner) {
    state.systemBanner = banner
  },
  setPendingPayPlan(state, plan) {
    state.pendingPayPlan = plan
  },
}

export const actions = {
  async fetchProductList({ commit }) {
    try {
      const resp = await this.$api.fetchProductList()
      if(resp.data.success) {
        commit('setAvailableCharacters', resp.data.data)
      }
    } catch (err) {
      alert(err)
    }
  },

  async getSystemBanner({ commit }) {
    const resp = await this.$api.getSystemBanner()
    const { data, success } = resp.data
    if (success) {
      commit('setSystemBanner', data)
      localStorage.setItem('systemBanner', data)
    }
  },
  async fetchMembership({ commit, state }) {
    if (state.user) {
      try {
        const response = await this.$api.getMemberShipDetail()
        const { success, data } = response.data
        if (success) {
          commit('setMembership', data)
        }
        // console.log("response:", response);
      } catch (e) {
        console.log('error:', e)
      }
    }
  },
  async onAuthStateChanged({ commit }, { authUser }) {
    if (!authUser) {
      return
    }
    if (authUser && authUser.getIdToken) {
      try {
        const idToken = await authUser.getIdToken(true)
        commit('setIdToken', idToken)
        // console.log("$api", this.$axios, this.$api);
      } catch (e) {
        console.error(e)
      }
    }
    commit('setUser', authUser)
  }
}
