
import GradientBackground from '@/components/GradientBackground.vue'
export default {
  name: 'BasePopup',
  comments: { GradientBackground },
  props: {
    visible: Boolean,
    closeIcon: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      invisible: true,
    }
  },
  computed: {
    isDark() {
      return this.$store.state.theme === 'dark'
    }
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.invisible = !newVal
      }
    },
  },
  methods: {
    close() {
      this.invisible = true
      this.$emit('close')
    },
  },
}
