
// 3EB8gm0D
import Money from 'dinero.js'
import DividerLine from '@/components/DividerLine.vue'
import GradientButton from '@/components/GradientButton.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import PaymentFailedPopup from '@/components/popup/PaymentFailedPopup.vue'

export default {
  name: 'MemberPlans',
  components: {
    GradientButton,
    DividerLine,
    LoadingSpinner,
    PaymentFailedPopup,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    productType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showPayFailedPopup: false,
      loading: true,
      plans: [],
      tps: [],
      pendingPayPlan: null,
      pendingId: null,
    }
  },
  async mounted() {
    if (this.productType === 2) {
      this.tps = JSON.parse(
        JSON.stringify(this.$store.state.proSubscriptionTps)
      )
    } else {
      this.tps = JSON.parse(
        JSON.stringify(this.$store.state.wellbeingSubscriptionTps)
      )
    }

    const memberships = this.$store.state.membership.memberships
    try {
      const resp = await this.$api
        .getPriceList(this.productId)
      const { success, data } = resp.data
      if (success) {
        if (data && data.length)
          this.plans = this.tps.filter((e) => {
            return data.filter((el) => {
              const id = `${el.interval}-${el.intervalCount}`
              console.log('id:', id, 'tId:', e.tId)
              return e.tId === id
            }).length
          })
        if (data && data.length)
          this.plans.forEach((e) => {
            const list = data.filter((el) => {
              const id = `${el.interval}-${el.intervalCount}`
              return id === e.tId
            })
            const plan = list[0]
            e.priceId = plan.priceId
            e.amount = plan.amount
            e.currency = plan.currency
            e.price = `${this.formatPrice(plan.amount, plan.currency)}`
            e.subscribed = memberships.filter(
              (el) => el.externalPriceId === e.priceId
            ).length
          })
      }
    } catch (e) {
      alert(e);
    } finally {
      this.loading = false
    }
  },
  methods: {
    retryPay(priceId) {
      const products = this.plans.filter((e) => e.priceId === priceId)
      if (products.length) {
        this.startPay(products[0])
      }
    },
    async startPay(item) {
      if (this.pendingId) {
        return
      }
      this.pendingId = item.priceId
      this.pendingPayPlan = item
      this.$store.commit('setPendingPayPlan', item)
      const successCallbackUrl = `https://www.chat.emoexai.com/checkout?session_id={CHECKOUT_SESSION_ID}`
      if (!item.priceId) {
        return
      }

      try {
        const resp = await this.$api.createSession(item.priceId, successCallbackUrl, successCallbackUrl)
        const { success, data } = resp.data
        if (success) {
          window.open(data, '_blank')
          // this.$router.replace({path: '/order/SuccessPayment', query: {o : encodeURIComponent(JSON.stringify(data))}})
        } else {
          this.showPayFailedPopup = true
        }
      } catch (err) {
        this.showPayFailedPopup = true
        console.log(err)
      } finally {
        this.pendingId = null
      }
    },
    formatPrice(price, currency) {
      const money = new Money({
        amount: price,
        precision: 2,
        currency,
      })
      return money.toFormat('$0,0.00')
    },
  },
}
