

import { mapMutations } from 'vuex'
import SvgSearchOutline from '@/components/svgs/SvgSearchOutline.vue'
import DividerLine from '@/components/DividerLine.vue'
import TitleBar from '@/components/TitleBar.vue'
import CharacterDetailPopup from '@/components/popup/CharacterDetailPopup.vue'
import EmoexInput from '@/components/EmoexInput.vue'

const searchIcon = require('@/static/search-ouline.svg')


export default {
  name: 'CharactersList',
  components: { DividerLine, TitleBar, CharacterDetailPopup, EmoexInput, SvgSearchOutline },
  props: {
    showTitleBar: {
      type: Boolean,
      default: true
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchIcon,
      character: null,
      showDetailDialog: false,
      maxHeight: '300',
      searchMode: false,
      keyword: ''
    }
  },
  computed: {
    isDark() {
      return this.$store.state.theme === 'dark'
    },
    canBack() {
      return this.$listeners['title:back']
    },
    list() {
      const ownlist = this.$store.state.membership.memberships
      const characters = this.$store.state.characters
      characters.forEach(e => {
        const list = ownlist.filter((el) => el.product.id === e.id)
        e.own = list.length && !e.free
        if (e.own) {
          e.expiredAt = list[0].expiredAt
        }
      })
      const sources = this.keyword.length ? characters.filter((e) => e.name && e.name.length && e.name.toLowerCase().includes(this.keyword.toLowerCase())) : characters
      const free = sources.filter((e) => e.free)
      const wellbeing = sources.filter((e) => !e.free && e.type === 1)
      const pro = sources.filter((e) => !e.free && e.type === 2)
      let res = [...free]
      if (wellbeing.length) {
        // wellbeing's type is 1
        res.push({ highlight: true, type: 1 })
        res = res.concat(wellbeing)
      }
      if (pro.length) {
        // pro's type is 1
        res.push({ highlight: true, type: 2 })
        res = res.concat(pro)
      }
      return res
    },
    containFreeList() {
      return this.list.filter((e) => e.free).length
    },
    index() {
      const character = this.$store.state.character
      return this.list.findIndex((e) => e.name === character.name)
    }
  },
  mounted() {
    this.maxHeight = this.$refs.rootEl.getBoundingClientRect().height * 0.95
    console.log('maxh:', this.maxHeight)
  },
  methods: {
    ...mapMutations([
      'setCharacter'
    ]),
    showNew(item) {
      const diff = this.$dayjs().diff(this.$dayjs(item.createdAt), 'day')
      // console.log(item.name, ",", diff);
      const clicked = localStorage.getItem(`click-${item.name}`) || false
      return diff <= 7 && !clicked
    },
    onSuffixClick() {
      this.searchMode = !this.searchMode
      this.keyword = ''
    },
    onSearch() {
    },
    fetchOwnedIcon(character) {
      if (character.type === 1) {
        return require('@/static/check-orange-badge.svg')
      } else {
        return require('@/static/check-badge.svg')
      }
    },
    onClickCharacter(item) {
      localStorage.setItem(`click-${item.name}`, true)
      if (!item.free) {
        // 如果已经购买了
        const { memberships } = this.$store.state.membership || { 'memberships': [] }
        const purchased = memberships.filter((e) => e.product.id === item.id).length
        if (purchased) {
          this.setCharacter(item)
          if (this.isMobile) this.onBack()
        } else {
          this.viewDetail(item)
        }
        // 如果没有购买

      } else {
        this.setCharacter(item)
        if (this.isMobile) this.onBack()
      }
    },
    onBack() {
      this.$router.replace('/')
    },
    viewDetail(item) {
      if (!item.free) {
        this.$router.push({
          path: `/character/${item.id}`
        })
      } else {
        this.character = item
        if (this.$device.isMobile) {
          this.$router.push({
            path: '/mobile/MobileFreeCharacterDetail',
            query: { id: item.id }
          })
        } else {
          this.showDetailDialog = true
        }
      }
    },
    onSetChat() {
      if (this.character) this.setCharacter(this.character)
      this.character = null
    }
  }
}
